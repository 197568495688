@font-face {
  font-family: "nuuday";
  font-style: normal;
  font-weight: 400;
  src: url("../font/Nuu-Regular.ttf"); /* IE9 Compat Modes */
  src: local("nuuday"), local("nuuday"),
    url("../font/Nuu-Regular.ttf?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../font/Nuu-Regular.ttf?") format("truetype"),
    /* Safari, Android, iOS */ url("../font/Nuu-Regular.ttf?#OpenSans")
      format("svg"); /* Legacy iOS */
}
/* @import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap'); */

body,
html {
  line-height: 1.8;
  font-family: "nuuday";
  color: #555e58;
  /* text-transform:capitalize; */
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 400;
  line-height: 1.5em;
}

p {
  font-size: 15px;
  margin: 12px 0 0;
  line-height: 24px;
}

a:hover {
  color: #555555;
  text-decoration: none;
  transition: all 0.3s;
}

button:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

button {
  cursor: pointer;
}

ul,
ol {
  padding: 0;
  margin: 0px;
  list-style: none;
}

.wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
}

/* ---------------------------------------------------
    SIDEBAR STYLE start
----------------------------------------------------- */

#sidebar {
  position: fixed;
  height: 100vh !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 260px;
  overflow: auto;
  transition: all 0.3s;
  background: #ffffff;
  box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%),
    0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

#sidebar::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #eee;
  display: none;
}
#sidebar::-webkit-scrollbar-thumbs {
  width: 5px;
  border-radius: 10px;
  background-color: #333;
  display: none;
}

#sidebar:hover::-webkit-scrollbar {
  display: block;
}
#sidebar:hover::-webkit-scrollbar-thumbs {
  display: block;
}

#sidebar .sidebar-header {
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
}

.sidebar-header h3 {
  color: #333;
  font-size: 17px;
  margin: 0px;
  transition: all 0.5s ease;
  font-weight: 600;
}

.sidebar-header h3 img {
  width: 81px;
  margin-right: 10px;
  margin-left: 2px;
}

#sidebar ul li {
  border: 1px solid #eee;
  background: #f9f9f9;
}

#sidebar ul li.active > a {
  color: #fff;
  background: linear-gradient(to right, #2196f3, #fffa1e);
}

#sidebar ul li a:hover {
  color: #333;
  background-color: rgba(200, 200, 200, 0.2) !important;
}

.dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 23px;
}

#sidebar ul li.drodown {
  position: sticky;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul li a {
  padding: 5px;
  line-height: 30px;
  border-radius: 5px;
  font-size: 15px;
  position: relative;
  font-weight: 400;
  display: block;
}

#sidebar ul li a span {
  /* text-transform:capitalize; */
  display: inline-block;
}

#sidebar ul li a i {
  position: relative;
  margin-right: 10px;
  top: 5px;
  color: #555555;
  margin-left: 10px;
}

label {
  white-space: nowrap;
}

button:disabled {
  cursor: not-allowed;
}

/* ---------------------------------------------------
   sidebar end
----------------------------------------------------- */

/*=======================================================
   main-content navbar-design start
   ===============================================*/

#content {
  position: relative;
  transition: all 0.3s;
  background-color: #eeeeee;
}

.top-navbar {
  width: 100%;
  z-index: 9;
  position: relative;
}

.main-content {
  padding: 10px 20px 0px 20px;
  position: relative;
  width: 100%;
}

.navbar {
  background: linear-gradient(to right, #2196f3, #fffa1e);
  color: #ffffff;
}

.navbar-brand {
  color: #ffffff;
}

.navbar button {
  background-color: transparent;
  border: none;
}
.navbar button span {
  color: #fff;
}

#sidebarCollapse {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  margin-right: 20px;
  border: none;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.09);
}

#sidebarCollapse span {
  margin: 9px;
  padding: 0px;
}

.selected {
  color: #ffffff;
  background: linear-gradient(to right, #2196f3, #fffa1e);
}

.navbar-nav > li.active {
  color: #ffffff;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}

.navbar-nav > li > a {
  color: #298ed2;
}

.navbar .notification {
  position: absolute;
  top: 5px;
  right: 10px;
  display: block;
  font-size: 9px;
  border: 0px;
  font-size: 10px;
  background: #d9534f;
  min-width: 15px;
  text-align: center;
  padding: 1px 5px;
  height: 15px;
  border-radius: 2px;
  line-height: 14px;
}

.navbar-nav > li.show .dropdown-menu {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.dropdown-menu li > a {
  font-size: 13px;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 150ms linear;
}

.navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.08);
}

.navbar-nav li a {
  position: relative;
  /* display: block; */
  padding: 10px 15px !important;
}

/*=======================================================
   main-content navbar-design end
   ===============================================*/

/*=======================================================
   main-content navbar-design start
   ===============================================*/

/*=======================================================
   main-content inner design like card start
   ===============================================*/

.card {
  margin: 10px 0;
}

.card {
  border-radius: 0px !important;
}

.card {
  display: inline-block;
  position: relative;
  border: none !important;
  width: 100%;
  margin: 15px 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
}

.card-stats .card-header {
  float: left;
  text-align: center;
}

.card .card-header {
  padding: 15px 20px 0;
  z-index: 3;
  /* background-color:#fff!important; */
  border-bottom: none;
}

.card .card-footer .stats .material-icons {
  position: relative;
  top: 4px;
  font-size: 16px;
}

.card-stats .card-header span {
  font-size: 36px;
  line-height: 56px;
  width: 56px;
  height: 56px;
}

.icon.icon-warning {
  color: #ff9800;
}

.icon.icon-rose {
  color: #e91e63;
}
.icon.icon-success {
  color: #4caf50;
}
.icon.icon-info {
  color: #00bcd4;
}
.card .card-footer {
  margin: 0 7px 0px;
  padding-top: 10px;
  background-color: #fff;
  border-top: 1px solid #eeeeee;
}

.text-info {
  color: #03a9f4 !important;
}

.card-stats .card-content {
  text-align: right;
  padding-top: 10px;
}

.card .card-content {
  padding: 15px 20px;
  position: relative;
}

.card .card-content .category {
  margin-bottom: 0;
}
.card .category:not([class*="text-"]) {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
}

.card .card-title:not(.card-calendar .card-title) {
  margin-top: 0;
  margin-bottom: 5px;
}

.card-stats .card-title {
  margin: 0;
}

.card .card-footer .stats {
  line-height: 22px;
  color: #555555;
  font-size: 15px;
}

.card .card-footer div {
  display: inline-block;
}

.card .card-header-text h4 {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 19px;
  font-weight: 400;
  color: #222222;
  text-decoration: none;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 16px;
  font-weight: 400;
}

.table > tbody > tr > td > i {
  color: #007bff;
  cursor: pointer;
}

.card .card-content {
  padding: 15px 20px;
  position: relative;
}

.streamline .sl-primary {
  border-left-color: #188ae2;
}

.streamline .sl-item {
  position: relative;
  padding-bottom: 12px;
  border-left: 1px solid #ccc;
}

.streamline .sl-item:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  background-color: #ccc;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
.streamline .sl-primary:before,
.streamline .sl-primary:last-child:after {
  background-color: #188ae2;
}

.streamline .sl-danger:before,
.streamline .sl-danger:last-child:after {
  background-color: #ff5b5b;
}

.streamline .sl-success {
  border-left-color: #10c469;
}

.streamline .sl-success:before,
.streamline .sl-success:last-child:after {
  background-color: #10c469;
}

.streamline .sl-warning {
  border-left-color: #f9c851;
}

.streamline .sl-warning:before,
.streamline .sl-warning:last-child:after {
  background-color: #f9c851;
}

.streamline .sl-danger {
  border-left-color: #ff5b5b;
}
.streamline .sl-item .sl-content {
  margin-left: 24px;
}

.streamline .sl-item .text-muted {
  color: inherit;
  opacity: 0.6;
  font-size: 12px;
}

.streamline .sl-item p {
  font-size: 14px;
  color: #333;
}

/*=======================================================
   main-content inner design like card end
   ===============================================*/

#sidebar.show-nav,
.body-overlay.show-nav {
  transform: translatex(0%);
  opacity: 1;
  display: block;
  visibility: visible;
  z-index: 15;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media only screen and (min-width: 992px) {
  #sidebar.active {
    width: 80px;
    height: 100% !important;
    position: absolute !important;
    overflow: visible !important;
    top: 0 !important;
    z-index: 666;
    float: left !important;
    bottom: 0 !important;
  }

  #sidebar.active .sidebar-header h3 span {
    display: none;
    transition: all 0.5s ease;
  }

  #sidebar.active ul li a span {
    display: none;
    transition: all 0.5s ease;
  }

  #sidebar.active .dropdown-toggle::after {
    display: none;
    transition: all 0.5s ease;
  }

  #content {
    width: calc(100% - 260px);
    position: relative;
    float: right;
    transition: all 0.3s;
    background-color: #eeeeee;
  }

  #content.active {
    width: calc(100% - 80px);
  }

  #sidebar.active .menu {
    position: absolute;
    left: 81px;
    background-color: white;
    width: 180px;
    height: auto;
    margin: 5px 0;
    top: 0;
    border: 1px solid #dcd9d9;
    z-index: 4;
  }
}

@media only screen and (min-width: 992px) {
  .dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    transform: translate3d(0, -20px, 0);
    visibility: hidden;
    transition: all 150ms linear;
    display: block;
    min-width: 15rem;
    right: 0;
    left: auto;
    opacity: 0;
  }
}

.delete-fallout {
  color: #6c757d;
  cursor: pointer;
}

.fallout-green {
  color: green;
}

.fallout-red {
  color: red;
}

.sync_select {
  background-color: #b9bbbd !important;
}

/*===============small-screen overlay sidebar design media queries==============*/

@media only screen and (max-width: 992px) {
  #sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: 260px;
    transform: translatex(-100%);
    transition: all 150ms linear;
    box-shadow: none !important;
  }

  .body-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    transition: all 150ms linear;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
/*===============small-screen overlay sidebar design media queries==============*/
