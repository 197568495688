.login-page{
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    font-family: 'Numans', sans-serif;
    }
    
    .login-container{
    height: 100%;
    align-content: center;
    }
    
    .login-card{
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
    }
    
    
    .login-card h3{
    color: white;
    } 
    
    .social_icon{
    position: absolute;
    width: 95px;
    right: 20px;
    top: -35px;
    }
    
    .login_btn{
    color: black;
    border: black;
    font-size: large;
    background: linear-gradient(to right, #2196F3, #fffa1e);
    }
    
    .login_btn:hover{
    color: black;
    background: linear-gradient(to right, #fffa1e,#2196F3);
    }
